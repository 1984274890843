import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sys_param:{},
    leftEye:'',
    rightEye:'',
    eyeText:'左眼',
    currentCorrectCount_left: 0,
    currentCorrectCount_right: 0,
    currentErrorCount_left: 0,
    currentErrorCount_right: 0,
    userId:null,
  },
  mutations: {
    set_currentCorrectCount_left(state,pay){
      state.currentCorrectCount_left=pay
    },
    set_currentCorrectCount_right(state,pay){
      state.currentCorrectCount_right=pay
    },
    set_currentErrorCount_left(state,pay){
      state.currentErrorCount_left=pay
    },
    set_currentErrorCount_right(state,pay){
      state.currentErrorCount_right=pay
    },
    set_sys_param(state,pay){
      state.sys_param=pay
    },
    set_left_eye(state,pay){
      state.leftEye=pay
    },
    set_right_eye(state,pay){
      state.rightEye=pay
    },
    set_eye_text(state,pay){
      state.eyeText=pay
    }, 
    set_userId(state,pay){
      state.userId=pay
    },
  },
  actions: {},
  modules: {},
  plugins: [
    // 默认储存在localstorage
    createPersistedState({
        // 本地储存名
        key: 'eye',
        // 指定模块
        // paths: ['user', 'cart', 'category']
    })
],
});
