<template>
  <div id="app" style="position:fixed;width:100%;height:100%;">
    <transition :name="transitionName">
      <router-view class="Router" v-if='isRouterAlive'></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide(){
    return{
      reload: this.reload
    }
  },
  data() {
    return {
      transitionName: '',
      isRouterAlive: true,
    }
  },
  created(){
    // 创建实例
    this.$vlf.createInstance({
        storeName: 'user'
    })
  },
  watch: {
    // '$route' (to, from) {
    //   const arr = ['index','setting','nearIndex','farIndex','highFarIndex','maculaIndex','history'];
    //   const compare = arr.indexOf(to.name)>arr.indexOf(from.name);
    //   if(to.name!=''){
    //     this.transitionName = compare ? 'slideleft' : 'slideright';
    //   }else{
    //     this.transitionName = '';
    //   }
    // }
  },
  // created(){
    // sessionStorage.setItem('clock',this.$time);
    // sessionStorage.setItem('isInterval',JSON.parse(this.$isInterval))
  // },
  methods:{
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function() {
         this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less">
@import "assets/less/index.less";
#app {
  font-family: '思源黑体CN';
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .fs(@base-font-size);
}
.Router {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
}
.slideleft-enter,
 .slideright-leave-active {
  opacity: 1;
  transform: translate3d(90% 0, 0);
  -webkit-transform: translate3d(90%, 0, 0);
  -moz-transform: translate3d(90%, 0, 0);
}
.slideleft-leave-active,
.slideright-enter {
 opacity: 1;
 transform: translate3d(-90% 0, 0);
 -webkit-transform: translate3d(-90%, 0, 0);
 -moz-transform: translate3d(-90%, 0, 0);
}
.transitionBody{
 transition: all 0.15s ease; /*定义动画的时间和过渡效果*/
}
</style>
