import axios from 'axios';
// import cookieOperate from "@/utils/auth.js";

const http = axios.create({
    //baseURL: 'http://desktop.bofangmedical.com:16006/',
    //baseURL:'http://192.168.2.143:9090',
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 1000 * 180
})
http.interceptors.request.use(
    config => {
    //   if(cookieOperate.getCookies("eye_token")){
    //     config.headers.common['token'] = cookieOperate.getCookies("eye_token")
    //   }
      return config;
  },err => {
    return Promise.reject(err);
  })
export default http;