import axios from 'axios'
import { authHeader } from './authHeader'
import { handelError } from './error'
import { Toast } from 'vant';
import Vue from 'vue'

// let baseUrl = object.baseUrl;
export default {
    get(url, param) {
        var service = axios.create({
            // baseURL: baseUrl,
            headers: authHeader()
        });
        
        return new Promise((cback, reject) => {
            service({
                method: 'get',
                url,
                params: param,
            }).then(res => {
                
                cback(res);
            }).catch(err => {
                
                // Toast({
                //     showClose: true,
                //     Toast: handelError(err),
                //     type: 'error'
                // })
                reject(err);
            })
        })
    },
    post(url, data) {
        var service = axios.create({
            // baseURL: baseUrl,
            headers: authHeader(),
            // responseType: 'blob'
        });
        
        return new Promise((cback, reject) => {
            service({
                method: 'post',
                url,
                data: data,
            }).then(res => {
                
                cback(res);
            }).catch(err => {
                
                // Toast(handelError(err));
                reject(err);
            })
        })
    },
    downloadFile(url, data) {
        var service = axios.create({
            // baseURL: baseUrl,
            // headers: {headers: {'Content-Type': 'application/json'},...authHeader()},
            headers: {headers: {'Content-Type': 'multipart/form-data'},...authHeader()},
            // responseType: 'blob'
        });
        
        return new Promise((cback, reject) => {
            service({
                method: 'post',
                url,
                data: data,
            }).then((res) => {
                cback(res);
            }).catch(err => {
                
                // Toast(handelError(err));
                reject(err);
            })
        })
    },
    put(url, data) {
        var service = axios.create({
            baseURL: baseUrl,
            headers: authHeader()
        });
        
        return new Promise((cback, reject) => {
            service({
                method: 'put',
                url,
                data: data,
            }).then(res => {
                
                cback(res);
            }).catch(err => {
                
                // Toast(handelError(err));
                reject(err);
            })
        })
    },
    delete(url) {
        var service = axios.create({
            baseURL: baseUrl,
            headers: authHeader()
        });
        
        return new Promise((cback, reject) => {
            service({
                method: 'delete',
                url
            }).then(res => {
                
                cback(res);
            }).catch(err => {
                
                // Toast(handelError(err));
                reject(err);
            })
        })
    },
}