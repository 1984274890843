import Vue from 'vue'
import App from './App.vue'
import router from './router'
import httpProxy from './commonJs/request.js'
import html5plus from 'html5pluspc';
import Vant from 'vant'
import 'vant/lib/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import touch from 'vue-directive-touch';
import http from "@/utils/request.js";
import store from './store'
import {iatRecorder} from './utils/iatRecorder'
import voiceInputButton from 'voice-input-button2'
Vue.use(voiceInputButton, {
  appId: '6b9d4170', // 您申请的语音听写服务应用的ID
  apiKey: '208cff0ff1bc87dbaaa7bca8354b3f84', // 您开通的语音听写服务的 apiKey
  apiSecret: 'ODI5NDQ2ZjU5MjRmZThjZTQwYWIwMDBl', // 您开通的语音听写服务的 apiSecret
  color: '#fff', // 按钮图标的颜色
  tipPosition: 'top' // 提示条位置
//   ... // 其他配置项, 参见下方 [Attributes / 属性] 部分
})

import Vlf from 'vlf'
import localforage from 'localforage'
Vue.use(Vlf, localforage)

Vue.use(touch);
Vue.use(ElementUI);
Vue.use(require('vue-wechat-title'))

Vue.prototype.$http = http;
// import Echarts from 'echarts'
Vue.use(Vant)
// Vue.prototype.echarts = Echarts
Vue.prototype.$httpProxy = httpProxy //接口请求地址
/**适配 */
import 'amfe-flexible';

Vue.prototype.$IatRecorder = iatRecorder;
Vue.prototype.$Elist = object.Elist;
Vue.prototype.$rowList = object.rowList;
Vue.prototype.$farVisionList = object.farVisionList;
Vue.prototype.$time =  object.time;
Vue.prototype.$isInterval =  object.isInterval;
Vue.prototype.$timeList =  object.timeList;
Vue.prototype.$hightFarVisionList =  object.hightFarVisionList;

Vue.config.productionTip = false

new Vue({
    router,
    store,
    data() {
        return {
            loginInfo:sessionStorage.getItem('loginInfo')?JSON.parse(sessionStorage.getItem('loginInfo')):''
        }
    },
    render: h => h(App)
}).$mount('#app')