import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
var router = new Router({
  // linkActiveClass: '',
  // mode: 'history',
  hashbang: true,
  history: false,
  routes: [
    
    // 首页
    {
      path: '/',
      name: 'index',
      component: (resolve) => require(['@/views/index'], resolve)
    },
    {
      path: '/setting',
      name: 'setting',
      component: (resolve) => require(['@/views/setting'], resolve)
    },
    {//近视力
      path: '/nearIndex',
      name: 'naerIndex',
      component: (resolve) => require(['@/views/nearVision/nearIndex'], resolve)
    },
    {//远视力
      path: '/farIndex',
      name: 'farIndex',
      component: (resolve) => require(['@/views/farVision/farIndex'], resolve)
    },
    {//老花视力
      path: '/highFarIndex',
      name: 'highFarIndex',
      component: (resolve) => require(['@/views/highFarVision/highFarIndex'], resolve)
    },
    {//眼底黄斑第一步
      path: '/maculaIndexStep1',
      name: 'maculaIndexStep1',
      component: (resolve) => require(['@/views/macula/maculaIndexStep1'], resolve)
    },
    {//眼底黄斑第2步
      path: '/maculaIndexStep2',
      name: 'maculaIndexStep2',
      component: (resolve) => require(['@/views/macula/maculaIndexStep2'], resolve)
    },
    {//眼底黄斑第3步
      path: '/maculaIndexStep3',
      name: 'maculaIndexStep3',
      component: (resolve) => require(['@/views/macula/maculaIndexStep3'], resolve)
    },
    {//眼底黄斑第4步
      path: '/maculaIndexStep4',
      name: 'maculaIndexStep4',
      component: (resolve) => require(['@/views/macula/maculaIndexStep4'], resolve)
    },
    {//眼底黄斑最后一步画布
      path: '/maculaIndexStep5',
      name: 'maculaIndexStep5',
      component: (resolve) => require(['@/views/macula/maculaIndexStep5'], resolve)
    },
    {//检查记录
      path: '/history',
      name: 'history',
      component: (resolve) => require(['@/views/history'], resolve)
    },
    {//检查记录
      path: '/historyItem',
      name: 'historyItem',
      component: (resolve) => require(['@/views/history_item'], resolve)
    },
    {//检查记录
      path: '/historyRecord',
      name: 'historyRecord',
      component: (resolve) => require(['@/views/historyRecord'], resolve)
    },
  ]
})
export default router